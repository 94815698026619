:root{
  --grey: #707070;
  --darker-grey: #CBCBCB;
  --medium-grey: #F0F0F0;
  --light-grey: #EFEFEF;
  --light-blue: #7EB5D2;
  --success: #33AF85;
  --green: #33AF85;
  --danger: #D0495E;
  --error: #F74762;
  --red: #D0495E;
  --blue: #005AA4;
  --primary: #285949;
  --bs-primary: #285949;
  --light-green: #33AF8540;
}

html,body{
  font-size:16px;
}
body {
  margin: 0;
  font-family: 'SpareBank1-regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TT */
.text-green{
  color: var(--green);
}
.bg-green{
  background-color: var(--green);
}
.narrow-60{
  width:60%;
  margin-left:20%;
  margin-right:20%;
}
.zz-top{
  z-index:9999;
}